const LocationData = [
    {
        City: "Anaheim",
        State: "CA",
        Id: "anaheimchimneysweep.org",
        Url: "https://www.anaheimchimneysweep.org",
        Phone: "951-382-5416"
    },
    {
        City: "Calabasas",
        State: "CA",
        Id: "calabasaschimneysweep.org",
        Url: "https://www.calabasaschimneysweep.org",
        Phone: "747-335-4637"
    },
    {
        City: "Corona",
        State: "CA",
        Id: "coronachimneysweep.org",
        Url: "https://www.coronachimneysweep.org",
        Phone: "951-355-2481"
    },
    {
        City: "Fontana",
        State: "CA",
        Id: "fontanachimneysweep.org",
        Url: "https://www.fontanachimneysweep.org",
        Phone: "951-724-3159"
    },
    {
        City: "Glendale",
        State: "CA",
        Id: "glendalechimneysweep.org",
        Url: "https://www.glendalechimneysweep.org",
        Phone: "747-447-7547"
    },
    {
        City: "Long Beach",
        State: "CA",
        Id: "longbeachchimneysweep.org",
        Url: "https://www.longbeachchimneysweep.org",
        Phone: "562-784-7079"
    },
    {
        City: "Moreno Valley",
        State: "CA",
        Id: "morenovalleychimneysweep.org",
        Url: "https://www.morenovalleychimneysweep.org",
        Phone: "951-338-8751"
    },
    {
        City: "Oxnard",
        State: "CA",
        Id: "oxnardchimneysweep.org",
        Url: "https://www.oxnardchimneysweep.org",
        Phone: "805-655-5068"
    },
    {
        City: "Pasadena",
        State: "CA",
        Id: "pasadenachimneysweep.org",
        Url: "https://www.pasadenachimneysweep.org",
        Phone: "213-786-4121"
    },
    {
        City: "Pomona",
        State: "CA",
        Id: "pomonachimneysweep.org",
        Url: "https://www.pomonachimneysweep.org",
        Phone: "951-420-7674"
    },
    {
        City: "Rancho Cucamonga",
        State: "CA",
        Id: "ranchocucamongachimneysweep.org",
        Url: "https://www.ranchocucamongachimneysweep.org",
        Phone: "951-477-5417"
    },
    {
        City: "Redlands",
        State: "CA",
        Id: "redlandschimneysweep.org",
        Url: "https://www.redlandschimneysweep.org",
        Phone: "951-499-5984"
    },
    {
        City: "Riverside",
        State: "CA",
        Id: "riversidechimneysweep.org",
        Url: "https://www.riversidechimneysweep.org",
        Phone: "951-307-1374"
    },
    {
        City: "Anaheim",
        State: "CA",
        Id: "anaheimchimneysweep.org",
        Url: "https://www.anaheimchimneysweep.org",
        Phone: "951-292-7463"
    },
    {
        City: "San Bernardino",
        State: "CA",
        Id: "sanbernardinochimneysweep.org",
        Url: "https://www.sanbernardinochimneysweep.org",
        Phone: "760-971-5596"
    },
    {
        City: "Santa Ana",
        State: "CA",
        Id: "santaanachimneysweep.org",
        Url: "https://www.santaanachimneysweep.org",
        Phone: "949-403-6730"
    },
    {
        City: "Temecula",
        State: "CA",
        Id: "temeculachimneysweep.org",
        Url: "https://www.temeculachimneysweep.org",
        Phone: "951-425-4072"
    },
    {
        City: "Thousand Oaks",
        State: "CA",
        Id: "thousandoakschimneysweep.org",
        Url: "https://www.thousandoakschimneysweep.org",
        Phone: "805-301-5848"
    },
    {
        City: "Torrance",
        State: "CA",
        Id: "torrancechimneysweep.org",
        Url: "https://www.torrancechimneysweep.org",
        Phone: "424-260-9781"
    },
    {
        City: "Ventura",
        State: "CA",
        Id: "venturachimneysweep.org",
        Url: "https://www.venturachimneysweep.org",
        Phone: "805-764-2864"
    },
]
export default LocationData;
