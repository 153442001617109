import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './Careers.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import AreaData from '../ServicesPage/AreaData'
import Checklist from '../../Images/checklist.png'
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import telephone from '../../Images/telephone.png'
import Chimney1 from '../../Images/Chimney1.jpeg'
import Chimney2 from '../../Images/Chimney2.jpeg'
import Chimney3 from '../../Images/Chimney3.jpeg'
import chimneysweep from '../../Images/chimneysweep.jpeg'

export default function Careers() {
    const navigate = useNavigate()


    return (
        <div className="Careers">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Anaheim Chimney Sweep | Careers</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Anaheim Chimney Sweep | Careers" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Anaheim Chimney Sweep | Careers" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.anaheimchimneysweep.org/careers" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="The career path at Anaheim Chimney Sweep is a rewarding one. Our employees are partners in the success of the brand, and that’s why we work hard to be a great place to work!" data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="The career path at Anaheim Chimney Sweep is a rewarding one. Our employees are partners in the success of the brand, and that’s why we work hard to be a great place to work!" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Faq Page" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Anaheim Chimney Sweep | Careers" data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Join the Anaheim Chimney Sweep Team</h1>
                    <h2>See Job Postings in Anaheim Area</h2>
                    <span>
                        <a href="tel:951-499-5984">CALL 951-499-5984<img src={telephone} alt="" /></a>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </span>
                </div>

            </div>
            <div className="CareersBanner">
                <h1>Why Work at Anaheim Chimney Sweep</h1>
                <h2>The career path at Anaheim Chimney Sweep is a rewarding one. Our employees are partners in the success of the brand, and that’s why we work hard to be a great place to work! As a locally recognized business, Anaheim Chimney Sweep has a strong presence in the community and is supported by a team of dedicated professionals who keep the company growing. We encourage employees to develop their skills and advance their careers within the company or through relocation opportunities to expand their horizons.</h2>
                <span>
                    <div className="CareersBannerLeft">
                        <img src={chimneysweep} alt="" />
                    </div>
                    <div className="CareersBannerRight">
                        <h4>Mike W</h4>
                        <h5>Anaheim Chimney Sweep - Anaheim, CA Branch</h5>
                        <h6>“I think Anaheim Chimney Sweep is a great choice for anyone who wants to succeed. You don’t sink or swim here; they help you along the way.”</h6>
                    </div>
                </span>
            </div>
            <div className="CareersBanner2">
                <h1>FIND A CAREER <br /> THAT'S RIGHT FOR YOU</h1>
                <div className="CareersBanner2List">
                    <div className="CareersBanner2ListTop">
                        <div className="CareersBanner2ListBox">
                            <img src={Chimney1} alt="" />
                            <h2>Technicians</h2>
                        </div>
                        <div className="CareersBanner2ListBox">
                            <img src={Chimney2} alt="" />
                            <h2>HR Managers</h2>
                        </div>
                        <div className="CareersBanner2ListBox">
                            <img src={Chimney3} alt="" />
                            <h2>Office Managers</h2>
                        </div>
                    </div>

                </div>
                <h2>Email us at careers@anaheimchimneysweep.org <br /> for more information</h2>
            </div>

            <NavigatorPath />

        </div>
    )
}

